import styled, { css } from 'styled-components';

var parseGapValue = gap => {
  if (typeof gap === 'number') {
    return "".concat(gap, "px");
  }

  return gap;
};

var parseHalfGapValue = gap => {
  return typeof gap === 'number' ? "".concat(gap / 2, "px") : "calc(".concat(gap, " / 2)");
}; // 用 @supports 检测 flex gap 属性不准确，所以用这个方法检测
// https://github.com/Modernizr/Modernizr/blob/master/feature-detects/css/flexgap.js


var checkFlexGap = () => {
  // create flex container with row-gap set
  var flex = document.createElement('div');
  flex.style.display = 'flex';
  flex.style.flexDirection = 'column';
  flex.style.rowGap = '1px'; // create two, elements inside it

  flex.appendChild(document.createElement('div'));
  flex.appendChild(document.createElement('div')); // append to the DOM (needed to obtain scrollHeight)

  document.body.appendChild(flex);
  var flexHeight = flex.scrollHeight; // determine if flex-gap is supported, accounting for Safari's bug

  var isSupported = flexHeight === 1 || flexHeight === 2;
  flex.parentNode.removeChild(flex);
  return isSupported;
};

export var FlexContainer = styled.div.withConfig({
  displayName: "FlexContainer",
  componentId: "sc-x6ttg3-0"
})(["display:flex;flex-direction:", ";flex-wrap:", ";justify-content:", ";align-items:", ";", ""], _ref => {
  var _ref$direction = _ref.direction,
      direction = _ref$direction === void 0 ? 'row' : _ref$direction;
  return direction;
}, _ref2 => {
  var _ref2$wrap = _ref2.wrap,
      wrap = _ref2$wrap === void 0 ? 'nowrap' : _ref2$wrap;
  return wrap;
}, _ref3 => {
  var _ref3$justify = _ref3.justify,
      justify = _ref3$justify === void 0 ? 'flex-start' : _ref3$justify;
  return justify;
}, _ref4 => {
  var _ref4$align = _ref4.align,
      align = _ref4$align === void 0 ? 'stretch' : _ref4$align;
  return align;
}, _ref5 => {
  var gap = _ref5.gap,
      rowGap = _ref5.rowGap,
      columnGap = _ref5.columnGap,
      _ref5$direction = _ref5.direction,
      direction = _ref5$direction === void 0 ? 'row' : _ref5$direction;
  var isSupportFlexGap = checkFlexGap();

  if (isSupportFlexGap) {
    return css(["gap:", ";row-gap:", ";column-gap:", ";"], gap ? parseGapValue(gap) : undefined, rowGap ? parseGapValue(rowGap) : undefined, columnGap ? parseGapValue(columnGap) : undefined);
  } // Fallback for browsers that don't support gap


  var gapValue = gap ? parseHalfGapValue(gap) : '0';
  var rowGapValue = rowGap ? parseHalfGapValue(rowGap) : gapValue;
  var columnGapValue = columnGap ? parseHalfGapValue(columnGap) : gapValue;

  var negativeMargin = value => value === '0' ? '0' : "-".concat(value);

  var isColumn = direction.includes('column');
  return css(["margin:", ";> *{", ""], isColumn ? "".concat(negativeMargin(rowGapValue), " 0") : "0 ".concat(negativeMargin(columnGapValue)), isColumn ? css(["margin:", " 0;&:first-child{margin-top:0;}&:last-child{margin-bottom:0;}"], rowGapValue) : css(["margin:0 ", ";&:first-child{margin-left:0;}&:last-child{margin-right:0;}"], columnGapValue));
});